<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    floating
    width="300"
  >
    <v-list color="transparent">
      <v-list-item class="mb-4">
        <v-list-item-avatar tile>
          <v-img
            :src="logoImage"
            contain
          />
        </v-list-item-avatar>

        <v-list-item-title>
          {{ $store.state.site.site.siteName ? $store.state.site.site.siteName : 'SITE NAME' }}
        </v-list-item-title>
        <span
          :style="{
            wordBreak: 'keep-all'
          }"
          class="mr-2 text-caption"
        >
          Theme
        </span>
        <v-switch
          v-model="isDark"
          @change="updateTheme"
        />
      </v-list-item>

      <template v-for="(item, i) in items">
        <v-list-item
          v-if="!item.spacer && item.show"
          :key="`tile-${i}`"
          :value="item.value"
          color="grey"
          exact
          @click="goToPage(item.to, item.params, item.query)"
          v-on="item.click && {
            'click': item.click
          }"
        >
          <v-list-item-avatar>
            <v-icon
              style="color: inherit"
              v-text="item.icon"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>

        <div
          v-else
          :key="`divider-${i}`"
          class="my-auto"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapMutations,
    mapState,
  } from 'vuex'

  export default {
    name: 'CoreDrawer',
    data () {
      return {
        isDark: JSON.parse(localStorage.getItem('isDark')),
        dark: localStorage.getItem('isDark'),
      }
    },
    computed: {
      ...mapState('downloads', {
        downloadDrawer: 'drawer',
      }),
      logoImage () {
        return this.$store.state.site.site.logoImage ? this.$store.state.site.site.logoImage : require('@/assets/logo/logo.png')
      },
      drawer: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
      items () {
        return [
          {
            icon: 'mdi-home-variant',
            text: '홈',
            to: 'Home',
            params: {
            },
            show: true,
          },
          {
            spacer: true,
            show: true,
          },
          {
            icon: 'mdi-bullhorn',
            text: '공지사항',
            to: 'Notice',
            params: {
              pageNum: 1,
            },
            show: true,
          },
          {
            spacer: true,
            show: true,
          },
          {
            icon: 'mdi-fire',
            text: '활동순위',
            to: 'Rank',
            params: {
            },
            show: true,
          },
          {
            spacer: true,
            show: true,
          },
          {
            icon: 'mdi-camera-plus',
            text: '구매/리뷰/배송',
            to: 'Board',
            params: {
              pageNum: 1,
            },
            show: true,
          },
          {
            icon: 'mdi-history',
            text: '한달리뷰',
            to: 'Board',
            params: {
              pageNum: 1,
            },
            query: {
              month: true,
            },
            show: true,
          },
          {
            spacer: true,
            show: true,
          },
          {
            icon: 'mdi-new-box',
            text: '신규상품',
            to: 'Product',
            params: {
              pageNum: 1,
            },
            show: true,
          },
          {
            spacer: true,
            show: true,
          },
          {
            icon: 'mdi-new-box',
            text: '신청내역',
            to: 'ProductRegistration',
            params: {
              pageNum: 1,
            },
            show: true,
          },
          {
            spacer: true,
            show: true,
          },
          {
            icon: 'mdi-account',
            text: '마이페이지',
            to: 'MyPage',
            params: {
            },
            show: true,
          },
          {
            spacer: true,
            show: true,
          },
          {
            icon: 'mdi-shield-account',
            text: '관리자',
            to: 'Manage',
            params: {
            },
            show: this.$store.state.user.user.userAuth === 'M' || this.$store.state.user.user.userAuth === 'A',
          },
        ]
      },
    },
    methods: {
      ...mapMutations('app', ['setDrawer']),
      goToPage (url, param, query) {
        if (url === 'Board') {
          this.$router.replace({
            name: url,
            param: param,
            query: query,
          }).catch(() => {})
        } else {
          this.$router.push({
            name: url,
            param: param,
            query: query,
          }).catch(() => {
          })
        }
      },
      updateTheme () {
        const isDark = this.isDark
        localStorage.setItem('isDark', isDark.toString())
        this.$vuetify.theme.dark = isDark
      },
    },
  }
</script>
